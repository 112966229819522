import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import qs from "qs";
import axios from "axios";
export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = true;
  reload = false;
  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }
  get isUserReloaded(): boolean {
    return this.reload;
  }
  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    // console.log(user);
    this.isAuthenticated = true;
    localStorage.setItem("u_id", user.id);
    this.user = user;
    JwtService.saveToken(user.token);
    delete user.token;
    // console.log(user);
    const jsonUser = JSON.stringify({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      imageProfile: user.imageProfile
    });
    localStorage.setItem("userInfo", jsonUser);
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = true;
    this.user = {} as User;
    // this.errors = [];
    // this.reload = true
    JwtService.destroyToken();
  }

  @Action
  async [Actions.LOGIN](credentials) {
    // console.log(credentials);
    /* eslint-disable */
    if (credentials.sub) {
      await axios.post(process.env.VUE_APP_API_URL + "/login", credentials ? qs.stringify({ googleId: credentials.sub }) : credentials)
        .then(async (res) => {
          // console.log(res);
          if (res.data.code == 200) {
            if (res.data.message == "new_google_account") {
              return await axios.post(process.env.VUE_APP_API_URL + "/register", credentials ? qs.stringify({
                email: credentials.email,
                username: credentials.email,
                googleId: credentials.sub,
                firstName: credentials.given_name,
                lastName: credentials.family_name,
                imageProfile: credentials.picture,
              }) : credentials)
                .then(async (res) => {
                  // console.log(res);
                  if (res.data.code == 200) {
                    await axios.post(process.env.VUE_APP_API_URL + "/login", res.data.data ? qs.stringify({ googleId: res.data.data.googleId }) : {})
                      .then(async (res) => {
                        if (res.data.code == 200) {
                          return this.context.commit(Mutations.SET_AUTH, res.data.data.profile);
                        } else {
                          return this.context.commit(Mutations.SET_ERROR, ["เข้าสู่ระบบไม่สำเร็จ"]);
                        }
                      }).catch((error) => {
                        // console.log(error);
                        return this.context.commit(Mutations.SET_ERROR, [error.response.data.message]);
                      });
                  } else {
                    return this.context.commit(Mutations.SET_ERROR, [res.data.message]);
                  }
                })
                .catch((error) => {
                  // console.log(error);
                  return this.context.commit(Mutations.SET_ERROR, [error.response.data.message]);
                });
            } else {
              return this.context.commit(Mutations.SET_AUTH, res.data.data.profile);
            }
          } else {
            return this.context.commit(Mutations.SET_ERROR, ["เข้าสู่ระบบไม่สำเร็จ"]);
          }
        })
        .catch((error) => {
          // console.log(error);
          return this.context.commit(Mutations.SET_ERROR, [error.response.data.message]);
        });
    } else {
      if (credentials.id) {
        await axios.post(process.env.VUE_APP_API_URL + "/login", credentials ? qs.stringify({ facebookId: credentials.id }) : credentials)
          .then(async (res) => {
            // console.log(res);
            if (res.data.code == 200) {
              if (res.data.message == "new_facebook_account") {
                return await axios.post(process.env.VUE_APP_API_URL + "/register", credentials ? qs.stringify({
                  email: credentials.email,
                  username: credentials.email,
                  facebookId: credentials.id,
                  firstName: credentials.first_name,
                  lastName: credentials.last_name,
                  imageProfile: credentials.picture.data.url,
                }) : credentials)
                  .then(async (res) => {
                    // console.log(res);
                    if (res.data.code == 200) {
                      await axios.post(process.env.VUE_APP_API_URL + "/login", res.data.data ? qs.stringify({ facebookId: res.data.data.facebookId }) : {})
                        .then(async (res) => {
                          if (res.data.code == 200) {
                            return this.context.commit(Mutations.SET_AUTH, res.data.data.profile);
                          } else {
                            return this.context.commit(Mutations.SET_ERROR, ["เข้าสู่ระบบไม่สำเร็จ"]);
                          }
                        }).catch((error) => {
                          // console.log(error);
                          return this.context.commit(Mutations.SET_ERROR, [error.response.data.message]);
                        });
                    } else {
                      return this.context.commit(Mutations.SET_ERROR, [res.data.message]);
                    }
                  })
                  .catch((error) => {
                    // console.log(error);
                    return this.context.commit(Mutations.SET_ERROR, [error.response.data.message]);
                  });
              } else {
                return this.context.commit(Mutations.SET_AUTH, res.data.data.profile);
              }
            } else {
              return this.context.commit(Mutations.SET_ERROR, ["เข้าสู่ระบบไม่สำเร็จ"]);
            }
          })
          .catch((error) => {
            // console.log(error);
            return this.context.commit(Mutations.SET_ERROR, [error.response.data.message]);
          });
      } else {
        await axios.post(process.env.VUE_APP_API_URL + "/login", credentials ? qs.stringify({ username: credentials.username, password: credentials.password }) : credentials)
          .then((res) => {
            // console.log(res);
            if (res.data.code == 200) {
              return this.context.commit(Mutations.SET_AUTH, res.data.data.profile);
            } else {
              return this.context.commit(Mutations.SET_ERROR, [res.data.data.message]);
            }
          })
          .catch((error) => {
            // console.log(error);
            if (error) {
              return this.context.commit(Mutations.SET_ERROR, ["เข้าสู่ระบบไม่สำเร็จ"]);
            }else{
              return this.context.commit(Mutations.SET_ERROR, ["เข้าสู่ระบบไม่สำเร็จ"]);
            }
           
          });
      }

    }
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("registration", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgetPassword", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          // console.log(response.data.errors);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  async [Actions.VERIFY_AUTH]() {
    // if (localStorage.getItem("tokenGuest") != null && localStorage.getItem("tokenGuest") != undefined) {
    //   const getGuestToken = await axios.get(
    //     process.env.VUE_APP_API_URL + "/getGuestToken"
    //   );
      // console.log(getGuestToken.data.data);
    //   localStorage.setItem("tokenGuest", getGuestToken.data.data);
    // }
    if (localStorage.getItem("u_id") != null && localStorage.getItem("id_token") != null) {
      axios
        .get(
          process.env.VUE_APP_API_URL +
          "/getCourse" +
          "?sizeContents=3&page=0&orderBy=2&sort=1&enable=1",
          {
            headers: {
              token: localStorage.getItem("id_token")
            }
          }
        )
        .then(({ data }) => {
          if (data.code != 200) {
          // console.log("error1");
            this.context.commit(Mutations.SET_ERROR, ["เซสชั่นหมดอายุ"]);
            this.context.commit(Mutations.PURGE_AUTH);
          }
        })
        .catch(({ error }) => {
          // console.log("error2");
          this.context.commit(Mutations.SET_ERROR, ["เซสชั่นหมดอายุ"]);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      // console.log("error3");
      this.context.commit(Mutations.SET_ERROR, ["เซสชั่นหมดอายุ"]);
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    if (localStorage.getItem("u_id") != null && localStorage.getItem("id_token") != null) {
      axios.get(process.env.VUE_APP_API_URL + "/getProfile?id=" + localStorage.getItem("u_id"), {
        headers: { token: localStorage.getItem("id_token") },
      }).then(({ data }) => {
        // console.log(data);
        // this.context.commit(Mutations.SET_USER, data.data);
      })
        .catch(({ error }) => {
          // console.log("error4");
          this.context.commit(Mutations.SET_ERROR, ["เซสชั่นหมดอายุ"]);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      // console.log("error5");
      this.context.commit(Mutations.SET_ERROR, ["เซสชั่นหมดอายุ"]);
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
